import { NavbarLogo } from "@UI/IconPack";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./Navigation.scss";
const navLinksList = [
  {
    to: "/",
    name: "Home",
  },
  {
    to: "/s1/features",
    name: "Features",
  },
  {
    to: "/s1/templates",
    name: "Templates",
  },

  {
    to: "/s1/pricing",
    name: "Pricing",
  },
];
const Navigation = () => {
  const navigate = useNavigate();
  const [moreAnchorEl, setMoreAnchorEl] = React.useState(null);
  return (
    <div className=" px-[2%]">
      <div className="flex items-center justify-between py-[32px]   top-0 left-0 w-full">
        <div className="flex items-center gap-6">
          <div className="-mt-[4%] -ml-[3%]">
            <NavbarLogo width={"142px"} height={"32px"} />
          </div>
          <div className="navigation-desk">
            <div className="flex items-center  gap-2   ">
              {navLinksList.map((link) => (
                <div key={link.to}>
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      `    ${
                        isActive
                          ? "selected-btn text-primary hover:dark:text-white hover:text-textPrimary-light bg-[#F9FAFB] dark:bg-[#1f242f]"
                          : "non-selected-btn hover:bg-[#F9FAFB] hover:dark:bg-[#1f242f] hover:dark:text-white text-[#94969C] hover:text-textPrimary-light"
                      }`
                    }
                  >
                    {link.name}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="navigation-desk">
          <div className="flex gap-3">
            <Button
              onClick={() => {
                navigate("/");
              }}
              variant="text"
              color="secondary"
            >
              Log In
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                navigate("/sign-up");
              }}
              // disabled={!isValidateKeyForm()}
            >
              Sign Up
            </Button>
          </div>
        </div>

        <div className="navigation-mobi">
          <div
            style={{ borderRadius: "16px", color: "white" }}
            onClick={(e) => {
              setMoreAnchorEl(e.currentTarget);
            }}
            className="flex items-center justify-center w-12 h-12 cursor-pointer"
          >
            <IconButton style={{ borderRadius: "16px", color: "white" }}>
              {!Boolean(moreAnchorEl) ? (
                <MenuIcon size="large" />
              ) : (
                <CloseIcon size="large" />
              )}
            </IconButton>
          </div>

          <div className="">
            <Menu
              style={{
                zIndex: "1999",
                marginTop: "37px",
                marginRight: "0px",
              }}
              sx={{
                "& .MuiMenu-list": {
                  padding: "0px",
                  background: "#1f242f",
                },
              }}
              // className="mt-16"
              anchorEl={moreAnchorEl}
              keepMounted={false}
              open={Boolean(moreAnchorEl)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              // MenuListProps={{
              //   disablePadding: true,
              // }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              onClose={() => setMoreAnchorEl(null)}
              PaperProps={{
                style: {
                  // maxHeight: 60 * 8.5,
                  width: "250px",
                  borderRadius: "8px",
                  // border: "1px solid #C4C4C4",
                  marginLeft: "0px",
                  marginTop: "20px",
                  border: "0px solid black",
                  background: "black",
                },
              }}
            >
              {navLinksList.map((link) => (
                <MenuItem
                  key={link.to}
                  style={{
                    background: "#1f242f",
                    border: "0px solid black",
                    margin: "-1px",
                  }}
                >
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      `    ${
                        isActive
                          ? "selected-btn text-primary hover:dark:text-white hover:text-textPrimary-light bg-[#F9FAFB] dark:bg-[#1f242f]"
                          : "non-selected-btn hover:bg-[#F9FAFB] hover:dark:bg-[#1f242f] hover:dark:text-white text-[#94969C] hover:text-textPrimary-light"
                      }`
                    }
                  >
                    {link.name}
                  </NavLink>
                </MenuItem>
              ))}

              <MenuItem style={{ background: "#1f242f", paddingTop: "10px" }}>
                <Button
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ padding: "10px" }}
                  fullWidth
                  variant="contained"
                >
                  Log In
                </Button>
              </MenuItem>
              <MenuItem
                style={{ background: "#1f242f", paddingBottom: "20px" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{ padding: "10px" }}
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                  // disabled={!isValidateKeyForm()}
                >
                  Sign Up
                </Button>{" "}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};
export default Navigation;
