import agentTools from "@Images/agent-tools.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const TemplateCard = ({
  logo,
  title,
  short_title,
  description,
  short_description,
  steps,
  footerText,
  templateId,
  integrations,
}) => {
  const navigate = useNavigate();
  return (
    <div className="model-data-block relative flex flex-col justify-between p-4 sm:p-6 md:p-6 min-h-[280px] sm:min-h-[300px] md:h-[320px] lg:h-[360px]">
      {logo && (
        <div className="absolute -top-4 sm:-top-6">
          <img src={logo} alt="" className="h-12 sm:h-14 md:h-16" />
        </div>
      )}
      <h1 className="text-primary font-semibold mt-[4%] text-lg sm:text-xl md:text-2xl">
        {short_title}
      </h1>
      <p className="mt-2 text-secondary text-sm sm:text-md">
        {short_description}
      </p>

      <div className="flex flex-wrap gap-2 sm:gap-4 items-center">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center gap-2">
            <div className="px-3 py-1 border-2 border-primary rounded-full">
              <h3 className="text-secondary text-xs sm:text-sm">{step}</h3>
            </div>
            {index !== steps.length - 1 && (
              <ArrowForwardIcon className="text-gray-500 text-sm sm:text-base md:text-lg" />
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center mt-2 sm:mt-4">
        <img src={agentTools} alt="" className="h-8 sm:h-10 md:h-12" />

        {footerText && (
          <h3
            className="text-secondary flex items-center gap-1 text-sm sm:text-md cursor-pointer"
            onClick={() =>
              navigate(`/s1/templates/${templateId}`, {
                state: {
                  templateId,
                  short_title,
                  title,
                  description,
                  short_description,
                  integrations,
                  steps,
                },
              })
            }
          >
            {footerText}{" "}
            <ArrowForwardIcon className="text-secondary text-xs sm:text-sm md:text-base" />
          </h3>
        )}
      </div>
    </div>
  );
};

export default TemplateCard;
