import { useAuth } from "@Components/Auth/AuthContext";
import snackbarService from "@Services.App/snackbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEnvironment } from "../../../../services/app/api";
import {
  addToEditEnv,
  fetchEnvironments,
  handleLoading,
  removeFromEditEnv,
  setSearchEnvironment,
} from "../../../../store/collectionsSlice";

import FilterField from "../../../UI/FilterField";
import { DuplicateIcon, TrashIcon } from "../../../UI/IconPack";
import MuiMenu from "../../../UI/MuiMenu";
import MuiModal from "../../../UI/MuiModal";
import SimplePagination from "../../../UI/SimplePagination";

const EnvironmentsList = () => {
  const newCollectionData = useSelector((state) => state.collectionsSlice);
  const { allEnvironments, searchEnvironment } = newCollectionData;
  const { activeTeam } = useAuth();
  const editEnvironmentsList = useSelector(
    (state) => state.collectionsSlice.editEnvironmentsList
  );
  const environmentData =
    editEnvironmentsList.filter((env) => env?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const [deleteEnvConfirmation, setDeleteEnvConfirmation] = useState(false);

  const EnvironmentItem = (env) => {
    const handelAddToEdit = () => {
      dispatch(
        addToEditEnv({
          ...env,
          isSaved: false,
          isEdited: false,
        })
      );
    };

    const handleDuplicate = () => {
      dispatch(
        addToEditEnv({
          ...{
            ...env,
            name: `${env?.name}-Copy`,
            id: `newEnvId${Math.random()}`,
          },
          isSaved: false,
          isEdited: true,
        })
      );
    };
    const handleRename = () => {
      handelAddToEdit();
    };
    const handleRemove = () => {
      setDeleteEnvConfirmation(env?.id);
    };
    const menuListItems = [
      // { icon: <EditIcon />, title: "Rename", onClick: handleRename },
      { icon: <DuplicateIcon />, title: "Duplicate", onClick: handleDuplicate },
      {
        icon: <TrashIcon color={"#F97066"} />,
        title: <span className="text-error">Remove</span>,
        onClick: handleRemove,
      },
    ];
    return (
      <div
        title={env?.name}
        onClick={handelAddToEdit}
        className={`group/item border-y-1 border-primary pl-4 cursor-pointer ${
          environmentData?.id === env?.id && "bg-quaternary"
        } `}
      >
        <div className="flex items-center my-2 justify-between h-8 w-64">
          <p className="text-sm text-primary  whitespace-nowrap overflow-hidden text-ellipsis">
            {env?.name}
          </p>
          <div className="invisible group-hover/item:visible">
            <MuiMenu menuListItems={menuListItems} />
          </div>
        </div>
      </div>
    );
  };

  const handleNextPageEnv = () => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: allEnvironments?.page + 1,
        limit: 10,
        searchKey: searchEnvironment,
      },
    };
    dispatch(fetchEnvironments(payload));
  };
  const handlePrevPageEnv = () => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: allEnvironments?.page - 1,
        limit: 10,
        searchKey: searchEnvironment,
      },
    };
    dispatch(fetchEnvironments(payload));
  };
  const handleDeleteEnvironment = () => {
    dispatch(handleLoading(true));
    deleteEnvironment(deleteEnvConfirmation)
      .then((res) => {
        var payload = {
          team_id: activeTeam,
          params: {
            page: 1,
            limit: 10,
            searchKey: searchEnvironment,
          },
        };
        dispatch(removeFromEditEnv(deleteEnvConfirmation));
        dispatch(fetchEnvironments(payload));
      })
      .catch((error) => {
        console.log(error);
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        setDeleteEnvConfirmation(false);
      });
  };

  const handleResetSearch = () => {
    dispatch(setSearchEnvironment(""));
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 10,
      },
    };
    dispatch(fetchEnvironments(payload));
  };
  return (
    <div className="relative top-0 h-full border-1 border-primary">
      <div className="p-2 flex gap-2 sticky top-0 z-50 bg-secondary">
        <FilterField
          value={searchEnvironment}
          onChange={(e) => dispatch(setSearchEnvironment(e.target.value))}
          onReset={handleResetSearch}
        />
      </div>
      <MuiModal
        open={!!deleteEnvConfirmation}
        handleClose={() => setDeleteEnvConfirmation(false)}
        title={"Delete Environment"}
        message={
          "Are you sure you want to delete this environment? This action cannot be undone."
        }
        handleConfirm={handleDeleteEnvironment}
        showDeleteIcon={true}
      />
      <div className="">
        {allEnvironments?.items?.length > 0 &&
          allEnvironments?.items?.map((env, index) => (
            <EnvironmentItem key={env?.id} {...{ ...env, index }} />
          ))}
      </div>
      {allEnvironments?.pages > 1 && (
        <div className="absolute bottom-0 z-50 flex justify-around items-center w-full border-t-1 bg-secondary border-primary">
          <SimplePagination
            onNext={handleNextPageEnv}
            onPrevious={handlePrevPageEnv}
            pagination={allEnvironments}
          />
        </div>
      )}
    </div>
  );
};

export default EnvironmentsList;
