import { CircularProgress, Grid, TextField } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getPromptEvaluation } from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import { FileTypeIcon } from "../../../UI/IconPack";
import CsvOutput from "../formatters/CsvOutput";
import ImageOutput from "../formatters/ImageOutput";
import JsonOutput from "../formatters/JsonOutput";
import OutputFormat from "../formatters/OutputFormat";
import XmlOutput from "../formatters/XmlOutput";
const PromptOutput = (props) => {
  const { outputFormatError, outputFormatOnFocus, isReadOnlyMode } = props;
  const { colors } = useThemeContext();
  const [evalText, SetEvalText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [evalData, SetEvalData] = useState({});
  const [cache, setCache] = useState({});
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList } = newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const Hr = () => <div className="border-b-1 my-3  border-primary" />;
  const Title = ({ children, className = "" }) => (
    <p
      className={`text-sm font-medium text-tertiary uppercase pb-5 ${className}`}
    >
      {children}
    </p>
  );
  const Label = ({ className = "", children }) => (
    <p className={`text-sm  text-primary  ${className}`}>{children}</p>
  );
  const Value = ({ className = "", children }) => (
    <p className={`text-2xl text-primary  ${className}`}>{children}</p>
  );

  console.log("prompt id:", promptData?.id);

  const evalHandler = useCallback(
    (id) => {
      const cacheKey = `${id}-${evalText}`;

      if (cache[cacheKey]) {
        SetEvalData(cache[cacheKey]);
        console.log("Loaded from cache:", cache[cacheKey]);
        return;
      }

      setIsLoading(true);

      getPromptEvaluation({ prompt_id: id, user_text: evalText })
        .then((res) => {
          setCache((prevCache) => ({
            ...prevCache,
            [cacheKey]: res,
          }));
          SetEvalData(res);
          console.log("Fetched from API:", res);
        })
        .catch((error) => {
          snackbar.error({
            title: `${error.error && error.error.toString()}`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [evalText, cache]
  );
  return (
    <>
      <Grid container px={10} py={2} spacing={2}>
        {!isReadOnlyMode && (
          <>
            <Grid item md={3.5}>
              <div className={"text-secondary font-semibold text-sm"}>
                Response format
              </div>
              <div className={"text-tertiary text-sm"}>
                Set output format to get results accordingly
              </div>
            </Grid>
            <Grid item md={8.5}>
              <OutputFormat
                error={outputFormatError}
                onFocus={outputFormatOnFocus}
              />
            </Grid>
          </>
        )}

        <div className="flex flex-col w-full">
          {!!promptData?.promptOutput && (
            <>
              {!isReadOnlyMode && (
                <div className="w-full border-t-1 border-secondary mt-[4%]" />
              )}

              <div className="flex items-start mt-[2%] justify-between">
                <div className="pl-4">
                  <h2 className="text-secondary font-semibold text-sm">
                    Response
                  </h2>
                  <h2 className={"text-tertiary text-sm mb-10"}>
                    Response for given prompt
                  </h2>
                </div>
                <div className="w-3/4 pl-14">
                  <div>
                    {promptData?.content_type === "Image" && (
                      <ImageOutput promptData={promptData} />
                    )}
                    {promptData?.content_type === "Text" &&
                      promptData?.output_format === "json" && (
                        <JsonOutput promptData={promptData} />
                      )}
                    {promptData?.content_type === "Text" &&
                      promptData?.output_format === "csv" && (
                        <CsvOutput promptData={promptData} />
                      )}
                    {promptData?.content_type === "Text" &&
                      promptData?.output_format === "xml" && (
                        <XmlOutput promptData={promptData} />
                      )}
                  </div>
                </div>
              </div>

              <div className="flex items-start justify-between border-t-1 border-secondary mt-[4%] pt-[2%]">
                <div className="pl-4 w-1/4">
                  <h2 className="text-secondary font-semibold text-sm">
                    Costs and best practices
                  </h2>
                  <h2 className={"text-tertiary text-sm mb-10"}>
                    We will help you optimize your costs.
                  </h2>
                </div>
                {promptData.response_state_result === "success" ? (
                  <div className="w-3/4 pl-14 ">
                    <div className="p-3 bg-secondary rounded-lg">
                      <div className="flex flex-col gap-2">
                        <h1 className="text-primary ">Results</h1>
                        <div className="flex justify-between items-center">
                          <h1 className="text-md text-secondary">
                            Response time
                          </h1>
                          <h2 className="text-md text-tertiary">
                            {promptData?.prompt_response_time} s
                          </h2>
                        </div>

                        <div className="flex justify-between items-center">
                          <h1 className="text-md text-secondary">
                            Number of Input tokens
                          </h1>
                          <h2 className="text-md text-tertiary">
                            {promptData?.prompt_input_tokens}
                          </h2>
                        </div>

                        <div className="flex justify-between items-center">
                          <h1 className="text-md text-secondary">
                            Number of O/p tokens
                          </h1>
                          <div className="flex items-end gap-1">
                            <h2 className="text-md text-tertiary">
                              {promptData?.prompt_output_tokens}
                            </h2>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <h1 className="text-md text-secondary">Cost</h1>
                          <h2 className="text-md text-tertiary">
                            ${promptData?.prompt_cost}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {promptData.response_state_result === "recall" && (
                  <div className="w-3/4">
                    <div className={"text-tertiary text-sm my-2"}>
                      Calculating prompt execution states
                    </div>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={130}
                    />
                  </div>
                )}
              </div>
              <div className="flex items-start justify-between border-t-1 border-secondary mt-[4%] pt-[2%]">
                <div className="pl-4 w-1/4">
                  <h2 className="text-secondary font-semibold text-sm">
                    Prompt Evaluation
                  </h2>
                  <h2 className={"text-tertiary text-sm mb-10"}>
                    Evaluate the performance of your prompt based on different
                    aspects, with an option to enter your desired output for
                    evaluation.
                  </h2>
                </div>
                <div className="w-3/4 pl-14">
                  <div className="p-3  flex flex-col gap-y-1 bg-secondary border-1 border-secondary rounded-lg">
                    <div className="mt-[1%] mb-[1%] ">
                      <TextField
                        className="w-full bg-primary"
                        value={evalText}
                        onChange={(e) => SetEvalText(e.target.value)}
                        multiline
                        minRows={3}
                        placeholder="Enter a sample output (optional)"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.borderSecondary,
                            borderWidth: "2px",
                          },
                        }}
                      />
                    </div>

                    <button
                      onClick={() => evalHandler(promptData?.id)}
                      className="px-4 py-2 text-primary w-[120px] bg-purple-50 dark:bg-gray-900  rounded-xl mt-[2%]  border-2 border-gray-200 hover:border-purple-600 dark:border-gray-900  dark:hover:border-purple-800"
                    >
                      Evaluate
                    </button>
                    <div className="mt-[1%]">
                      {isLoading ? (
                        <div className="flex justify-center items-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        ""
                      )}
                      {!isLoading && Object.keys(evalData).length > 0 && (
                        <div className="flex flex-col gap-y-2">
                          <h3 className="text-primary text-lg">
                            Evaluation Result
                          </h3>
                          {Object.entries(evalData).map(([key, value]) => (
                            <div key={key}>
                              <div className="flex  items-center">
                                <div className="flex-1">
                                  <h1 className="text-secondary">{key}</h1>
                                </div>

                                <div className="flex">
                                  <h2 className="text-tertiary text-center font-semibold">
                                    {value}
                                  </h2>
                                </div>

                                <div className="relative h-1 bg-gray-600 w-[100px] p-1 overflow-hidden  rounded-full  ml-8">
                                  <div
                                    className={`absolute h-1 p-1 ${
                                      value < 5
                                        ? "bg-red-500"
                                        : value === 5
                                        ? "bg-yellow-500"
                                        : "bg-green-500"
                                    }`}
                                    style={{
                                      width: `${(value / 10) * 100}%`, // Width based on the value (1-10)
                                      position: "absolute",
                                      left: 0,
                                      top: 0,
                                      transition: "width 1s ease", // Optional smooth transition
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>

      {isReadOnlyMode && !promptData?.promptOutput && (
        <div className="relative flex items-center justify-center ">
          <FileTypeIcon />
          <div className="absolute bottom-32 text-base text-center  text-primary">
            Run prompt to see response
          </div>
        </div>
      )}
    </>
  );
};

export default PromptOutput;
