import { llmModelsIcon } from "@/services/constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, TextField } from "@mui/material";
import { getCalculation } from "@Services.App/api";
import snackbarService from "@Services.App/snackbar";
import { handleLoading } from "@Store/collectionsSlice";
import { NavbarLogo, NonSelectedIcon, SelectedRoundIcon } from "@UI/IconPack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LLMdata from "./llm-descriptions.json";
import Navigation from "./Navigation";
const LlmDescription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTag, setSelectedTag] = useState("token");
  const [input, setInput] = useState("");
  const [outPut, setOutPut] = useState("");
  const [limit, setLimit] = useState(10);
  const [apiCalls, setApiCalls] = useState("");
  const [modelData, setModelData] = useState([]);
  const { model, size, color, modelName } = location.state || {};

  const llm = LLMdata.models.find((model) => model.name === modelName);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGetCalculation = (
    input,
    outPut,
    apiCalls,
    page = 1,
    search_key = ""
  ) => {
    if (input == "") {
      snackbarService.error({
        title: `Input ${selectedTag} can't be empty.`,
      });
      return;
    }
    if (outPut == "") {
      snackbarService.error({
        title: `OutPut ${selectedTag} can't be empty.`,
      });
      return;
    }
    if (apiCalls == "") {
      snackbarService.warning({
        title: `ApiCalls is empty, proceeding with 0 calls.`,
      });
      apiCalls = 0;
    }
    dispatch(handleLoading(true));

    getCalculation({
      params: {
        input_tokens: input,
        output_tokens: outPut,
        type: selectedTag,
        api_calls: apiCalls,
        page: 1,
        limit: limit,
        searchKey: search_key,
        model_name: modelName,
      },
    })
      .then((res) => {
        setModelData(res.items);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  if (!llm) {
    return (
      <div className=" bg-black min-h-screen pb-[5%] px-[5%] ">
        <div className="">
          <Navigation />
          <div className="flex items-center  md:mt-[2%] mb-[10%]">
            <h1
              className="text-primary cursor-pointer text-md"
              onClick={() => navigate("/s1/pricing")}
            >
              Pricing
            </h1>
            <KeyboardArrowRightIcon />
            <h1 className="text-primary text-md py-1 px-3 rounded-lg bg-secondary">
              {modelName.toUpperCase()}
            </h1>
          </div>
          <p className="text-primary display-sm-semibold">
            Description not available for this model.
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className=" bg-black min-h-screen pb-[5%]">
        <div className="px-[5%]">
          <Navigation />
          <div className="flex items-center px-[1%] md:mt-[2%]">
            <h1
              className="text-primary cursor-pointer text-md"
              onClick={() => navigate("/s1/pricing")}
            >
              Pricing
            </h1>
            <KeyboardArrowRightIcon />
            <h1 className="text-primary text-md py-1 px-3 rounded-lg bg-secondary">
              {modelName.toUpperCase()}
            </h1>
          </div>
          <div className="mt-[5%]   flex gap-2 md:gap-3 items-center">
            <p className="w-[36px]  md:w-[64px]">
              {llmModelsIcon(size, color)[model]}
            </p>
            <p className="text-primary display-sm-semibold md:display-md-semibold ">
              {modelName.toUpperCase()}
            </p>
          </div>
          <div className="px-[0.5%]">
            <p className="text-tertiary text-xl  md:w-1/2 mt-[4%] md:mt-[2%]">
              {llm?.description}
            </p>
            <div className="md:mt-[5%] mt-[8%] text-xl">
              <h1 className="display-xs-semibold mb-[2%] text-primary">
                MORE ABOUT THIS LLM
              </h1>
              <p className="text-tertiary flex pb-2 md:pb-0 ">
                <span className="font-semibold  mr-2">&#8226;</span>
                <p2>
                  <span className=" font-semibold">Research : </span>
                  {llm?.more_about?.Research}
                </p2>
              </p>
              <p className="text-tertiary flex pb-2 md:pb-0">
                <span className="font-semibold  mr-2">&#8226;</span>
                <p2>
                  {" "}
                  <span className=" font-semibold">Infrastructure : </span>
                  {llm?.more_about?.Infrastructure}
                </p2>
              </p>
              <p className="text-tertiary flex pb-2 md:pb-0">
                <span className="fo mr-2">&#8226;</span>
                <p2>
                  <span className=" font-semibold">Limitations : </span>
                  {llm?.more_about?.Limitations}
                </p2>
              </p>
              <p className="text-tertiary flex pb-2 md:pb-0">
                <span className="font-semibold  mr-2">&#8226;</span>
                <p2>
                  <span className=" font-semibold">Availability : </span>
                  {llm?.more_about?.Availability}
                </p2>
              </p>
            </div>
          </div>
          <div className="container-main-cur mt-16 ">
            <div className="block-pr4">
              <div className="flex gap-3  items-center justify-center mb-8 inputblock">
                {[
                  { tag: "token", name: "Tokens" },
                  { tag: "word", name: "Words" },
                  { tag: "char", name: "Characters" },
                ].map((item) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedTag(item.tag);
                      }}
                      className={
                        selectedTag == item.tag
                          ? "none-selected-block w-[240px]"
                          : "selected-block w-[240px]"
                      }
                    >
                      <div className="text-md-medium text-white">
                        {item.name}
                      </div>{" "}
                      <div className="block-inner1">
                        {selectedTag == item.tag ? (
                          <SelectedRoundIcon />
                        ) : (
                          <NonSelectedIcon />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex gap-3 items-center justify-center inputblock">
                <div>
                  <div className="text-sm-medium text-secondary mb-3">
                    Input {selectedTag}
                  </div>
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      background: "#0C111D",
                      "& .MuiOutlinedInput-input": {
                        // background: "red",
                        // borderLeft: "1px solid #D0D5DD",

                        color: "white",
                      },
                      color: "white",
                    }}
                    // onWheel={(e) => e.target.blur()}
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    type="number"
                    fullWidth
                    placeholder="Enter input tokens"
                  />
                </div>
                <div>
                  <div className="text-sm-medium text-secondary mb-3">
                    Output {selectedTag}
                  </div>
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      "& .MuiOutlinedInput-input": {
                        // background: "red",
                        // borderLeft: "1px solid #D0D5DD",

                        color: "white",
                      },
                      background: "#0C111D",
                    }}
                    value={outPut}
                    onChange={(e) => {
                      setOutPut(e.target.value);
                    }}
                    type="number"
                    fullWidth
                    placeholder="Enter output tokens"
                  />
                </div>
                <div>
                  <div className="text-sm-medium text-secondary mb-3">
                    API calls
                  </div>
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      "& .MuiOutlinedInput-input": {
                        // background: "red",
                        // borderLeft: "1px solid #D0D5DD",

                        color: "white",
                      },
                      background: "#0C111D",
                    }}
                    value={apiCalls}
                    onChange={(e) => {
                      setApiCalls(e.target.value);
                    }}
                    type="number"
                    fullWidth
                    placeholder="Enter API calls"
                  />
                </div>
              </div>

              <div className="flex  items-center justify-center mt-8">
                <Button
                  variant="contained"
                  style={{ width: "140px" }}
                  onClick={() => {
                    handleGetCalculation(
                      input,
                      outPut,
                      apiCalls,
                      1,
                      "",
                      modelName
                    );
                  }}
                >
                  Calculate cost
                </Button>
              </div>
            </div>
          </div>
          <div className="container-main-cur mt-6 bg-secondary rounded-lg ">
            {modelData.map((item) => (
              <div
                key={item.id}
                className="px-24 py-5 flex flex-col md:flex-row items-start md:items-center justify-between gap-4"
              >
                <div className="flex flex-col items-start md:items-center">
                  <h2 className="text-md text-tertiary">Input token price</h2>
                  <p className="text-xl text-primary">
                    {" "}
                    ${Number(item.input_token_price).toFixed(3)}
                  </p>
                </div>
                <div className="flex flex-col items-start md:items-center">
                  <h2 className="text-md text-tertiary">Output token price</h2>
                  <p className="text-xl text-primary">
                    {" "}
                    ${Number(item.output_token_price).toFixed(3)}
                  </p>
                </div>
                <div className="flex flex-col items-start md:items-center">
                  <h2 className="text-md text-tertiary">Total token price</h2>
                  <p className="text-xl text-primary">
                    {" "}
                    ${Number(item.total_token_price).toFixed(3)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-main-cur  bg-black">
        <div className="datafooter items-center justify-between py-[32px] px-[5%]  w-full">
          <div className="flex items-center ">
            <div>
              <NavbarLogo width={"142px"} height={"32px"} />
            </div>
          </div>
          <div className="text-md-regular  text-[#94969C]">
            © 2024 Scalaix. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default LlmDescription;
