import snackbarService from "@Services.App/snackbar";
import MuiCheckbox from "@UI/MuiCheckbox";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormControlLabel, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditEnv } from "../../../../store/collectionsSlice";
import FilledTextField from "../../../UI/FilledTextField";
import MuiSelect from "../../../UI/MuiSelect";
const EnvironmentData = ({ filesData }) => {
  const editEnvironmentsList = useSelector(
    (state) => state.collectionsSlice.editEnvironmentsList
  );
  const environmentData =
    editEnvironmentsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();

  const VariablesBar = ({ data, index }) => {
    const [key, setKey] = useState(data?.key);
    const [value, setValue] = useState(data?.value);
    const [isEmbed, setEmbed] = useState(data?.embed_file);
    const handleChangeFields = (e, field) => {
      let envDataChange = environmentData?.variables?.map((item) => ({
        ...item,
      }));
      envDataChange[index][field] = e.target.value;
      if ("file_id" == field) {
        var is_file_embedded = false;
        filesData.map((data_item) => {
          if (data_item.id == e.target.value) {
            if (data_item.embedded) {
              is_file_embedded = true;
            }
          }
        });

        envDataChange[index]["embed_file"] = is_file_embedded;
      }
      let data = {
        ...environmentData,
        variables: envDataChange,
      };
      dispatch(handleChangEditEnv(data));
    };

    const handleChangeInCheckbox = (e, field) => {
      let envDataChange = environmentData?.variables?.map((item) => ({
        ...item,
      }));
      envDataChange[index][field] = e.target.checked;
      let data = {
        ...environmentData,
        variables: envDataChange,
      };
      dispatch(handleChangEditEnv(data));
    };
    const handleDeleteVariable = () => {
      let envDataChange = environmentData?.variables.filter(
        (item, ind) => ind !== index
      );
      let data = {
        ...environmentData,
        variables: envDataChange,
      };
      dispatch(handleChangEditEnv(data));
    };
    return (
      <div className="border-1 border-secondary bg-secondary flex rounded-lg first:mt-0 last:mb-0 my-4">
        <div className="p-1 text-[10px] flex justify-center items-center bg-brand-secondary rounded-tl-lg rounded-bl-lg ">
          {index + 1}
        </div>
        <div className="w-full">
          <div className="px-2 py-3 flex gap-2">
            <div className={`w-[40%]`}>
              <label
                htmlFor={`variableType`}
                className="text-secondary text-sm font-medium mb-1"
              >
                Variable Type
              </label>
              <MuiSelect
                id={"variableType"}
                menuItems={[
                  { value: "text", name: "Text" },
                  // { value: "image", name: "Image" },
                  { value: "file", name: "File" },
                ]}
                value={data?.type}
                onChange={(e) => handleChangeFields(e, "type")}
                valueKey={"value"}
                selectClassName={"bg-primary"}
                labelKey={"name"}
              />
            </div>
            <div className="w-[40%]">
              <FilledTextField
                label={"Key"}
                value={key}
                onChange={(e) => setKey(e.target.value)}
                onBlur={(e) => handleChangeFields(e, "key")}
                placeholder={"Enter key"}
              />
            </div>
            {data?.type === "text" && (
              <div className="w-full">
                <FilledTextField
                  value={value}
                  label={"Value"}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={(e) => handleChangeFields(e, "value")}
                  placeholder={"Enter value"}
                />
              </div>
            )}
            {data?.type !== "text" && (
              <div className="w-full">
                <label
                  htmlFor={`file`}
                  className="text-secondary text-sm font-medium mb-1"
                >
                  File
                </label>
                <MuiSelect
                  menuItems={filesData}
                  value={data?.file_id}
                  onChange={(e) => handleChangeFields(e, "file_id")}
                  valueKey={"id"}
                  selectClassName={"bg-primary"}
                  labelKey={"name"}
                  id={"file"}
                />
              </div>
            )}
            {data?.type !== "text" && (
              <div className=" ml-4 w-56">
                <label
                  htmlFor={`Embed file`}
                  className="text-secondary text-sm font-medium mb-1"
                >
                  Embed file
                </label>
                <FormControlLabel
                  control={<MuiCheckbox />}
                  label={``}
                  className="text-sm text-tertiary"
                  checked={data?.embed_file}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmbed(e.target.checked);
                      handleChangeInCheckbox(e, "embed_file");
                    } else {
                      snackbarService.error({
                        title: `Once a file is embedded, you can undo this step.`,
                      });
                    }
                  }}
                />
              </div>
            )}
            <div>
              <IconButton size="small" onClick={handleDeleteVariable}>
                <Close fontSize="small" className="text-tertiary" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleAddMoreVariables = () => {
    let envDataChange = environmentData?.variables.map((item) => ({
      ...item,
    }));
    envDataChange.push({
      key: "",
      value: "",
      type: "text",
      file_id: null,
    });
    let data = {
      ...environmentData,
      variables: envDataChange,
    };
    dispatch(handleChangEditEnv(data));
  };
  return (
    <div className="bg-primary h-[calc(100%-100px)]  overflow-auto">
      <Grid container px={10} py={2} spacing={2}>
        <Grid item md={3}>
          <div className={"text-secondary font-semibold text-sm"}>
            Environment
          </div>
          <div className={"text-tertiary text-sm"}>
            Add environment variables to use in prompt
          </div>
        </Grid>
        <Grid item md={9}>
          {environmentData?.variables?.length > 0 &&
            environmentData?.variables?.map((data, index) => (
              <VariablesBar key={index} data={data} index={index} />
            ))}
          <div className="flex justify-end">
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddMoreVariables}
              variant="outlined"
              color="secondary"
            >
              Add variable
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EnvironmentData;
