import FilledTextField from "@UI/FilledTextField";
import { ReloadIcon } from "@UI/IconPack";
import MuiSelect from "@UI/MuiSelect";
import { Button } from "@mui/material";
import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { llmModelsIcon } from "../../../../services/constants";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CompareData, { AverageResult } from "./CompareData";

const PromptCompare = ({ handleComparePrompt, handleResetCompare }) => {
  const templateRef = useRef(null);
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList, modelsVersionsList, aiModelsList, allPromptsData } =
    newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const averageData = promptData?.compareResultResponse?.compare_data;
  const dispatch = useDispatch();
  const { colors } = useThemeContext();

  const [selectedCriterion, setSelectedCriterion] = useState("Response Time");

  const handleCriterionChange = (event) => {
    setSelectedCriterion(event.target.value);
  };

  const handleIterationChange = (_, v) => {
    let data = {
      ...promptData,
      iteration_cnt: v,
    };
    dispatch(handleChangEditPrompt(data));
  };

  const menuList = [
    { value: "Response Time", label: "Response Time" },
    { value: "Cost", label: "Cost" },
  ];

  const getWinnerIndex = () => {
    if (!averageData || averageData.length < 2) return null;
    const winnerModel =
      selectedCriterion === "Response Time"
        ? promptData?.compareResultResponse?.time_win_model
        : promptData?.compareResultResponse?.cost_win_model;

    if (!winnerModel || winnerModel.failure > 0) {
      return null;
    }

    const winnerIndex = averageData.findIndex(
      (model) => model.model_version_id === winnerModel.id
    );
    return winnerIndex >= 0 ? winnerIndex : null;
  };

  const winnerIndex = getWinnerIndex();

  const createPdg = () => {
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        format: "letter",
        unit: "px",
        hotfixes: ["px_scaling"],
      });

      const html = templateRef.current;

      var tr_div = document.createElement("div");
      tr_div.style.color = "black";
      tr_div.innerHTML = document.getElementById("sssssssssssssss").innerHTML;
      doc.html(tr_div, {
        callback(doc) {
          doc.save("result");
        },
        margin: [40, 20, 40, 20],
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="p-5  w-full">
      <div
        className="bg-secondary border-1 rounded-lg border-secondary py-6 px-5 flex gap-2 items-center justify-between"
        display={"flex"}
        px={10}
        m={"auto"}
      >
        <div className="flex gap-2 items-center">
          <div className="text-md-medium"> Iteration count:</div>
          <div className="w-40">
            <FilledTextField
              disabled={promptData?.comparisonProgress}
              value={promptData?.iteration_cnt}
              type="number"
              onChange={(e) => handleIterationChange("value", e.target.value)}
            />
          </div>
          <div className="text-md-medium"> Objective</div>
          <div className="w-50">
            <MuiSelect
              menuItems={menuList}
              value={selectedCriterion}
              onChange={handleCriterionChange}
            />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          {averageData ? (
            <div className="flex gap-2 items-center">
              <Button
                startIcon={<ReloadIcon />}
                variant="outlined"
                color="secondary"
                disabled={promptData?.comparisonProgress}
                onClick={handleResetCompare}
                sx={{ background: colors.bgPrimary }}
              >
                Reset Comparison
              </Button>
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                handleComparePrompt();
              }}
              disabled={promptData?.comparisonProgress}
            >
              Compare Models
            </Button>
          )}
        </div>
      </div>

      <div ref={templateRef} className="hidden" id="sssssssssssssss">
        <div className="my-5 grid grid-cols-2 gap-6 w-full">
          {promptData?.compare_data.map(
            (compare, index) =>
              !!averageData && (
                <div key={index} className="w-full">
                  <div className="text-md-medium flex gap-2 items-center">
                    {
                      llmModelsIcon("40px")[
                        compare?.aiModelsList?.filter(
                          (item) => item.id === compare?.llmModel
                        )?.[0]?.name
                      ]
                    }
                    <p>
                      {
                        compare?.aiModelsList?.filter(
                          (item) => item.id === compare?.llmModel
                        )?.[0]?.name
                      }
                    </p>
                    <p>
                      {
                        compare?.modelsVersionsList?.filter(
                          (item) => item.id === compare?.model_version_id
                        )?.[0]?.name
                      }
                    </p>
                  </div>
                  <div className="border-t-1 border-primary my-5" />
                  <p>Result</p>
                  <AverageResult
                    data={averageData[index]}
                    data1={averageData[index === 0 ? 1 : 0]}
                  />
                </div>
              )
          )}
        </div>
      </div>

      <div className="w-full flex gap-8 pb-12">
        {promptData?.compare_data.map((compare, index) => (
          <CompareData
            key={index}
            compare={compare}
            index={index}
            isWinner={index === winnerIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default PromptCompare;
