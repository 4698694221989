import gmailLogo from "@Images/gmail.png";
import googleSheetLogo from "@Images/google-sheet.png";
import IntegrationLogo from "@Images/integration.png";
import pdfExtractorLogo from "@Images/pdf-extractor.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAgentTemplates } from "../../services/app/api";
import snackbar from "../../services/app/snackbar";
import { NavbarLogo } from "../UI/IconPack";
import TemplateCard from "../UI/TemplateCard";
import Navigation from "./Navigation";

const AgentTemplateDetails = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const limit = 2;
  const location = useLocation();
  const navigate = useNavigate();
  const {
    templateId,
    short_title,
    title,
    description,
    integrations,
    steps,
    short_description,
  } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTemplates();
  }, []);

  const fetchTemplates = () => {
    setLoading(true);
    getAgentTemplates({ tag: "Education", limit })
      .then((response) => {
        setTemplates(response || []);
      })
      .catch((error) =>
        snackbar.error(error.error || "Failed to fetch templates")
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const getLogo = (title) => {
    if (title === "Email PDF attachment Extractor") return gmailLogo;
    if (title === "PDF to Google Sheet Extractor") return googleSheetLogo;
    return pdfExtractorLogo;
  };

  return (
    <main className="px-[5%] bg-black ">
      <div className="min-h-screen">
        <div className="">
          <Navigation />
        </div>
        <div className="flex items-center px-[1%] md:mt-[2%]">
          <h1
            className="text-primary cursor-pointer text-md"
            onClick={() => navigate("/s1/templates")}
          >
            Templates
          </h1>
          <KeyboardArrowRightIcon />
          <h1 className="text-primary text-sm md:text-md py-1 px-3 rounded-lg bg-secondary">
            {title}
          </h1>
        </div>
        <div className="px-[5%]  flex justify-center items-center flex-col gap-6 py-16 text-white ">
          <div className=" font-semibold text-2xl md:text-4xl  text-center">
            {short_title || "No Title"}
          </div>
          <div className="md:text-lg-regular text-sm text-tertiary text-center">
            {short_description || "No description available."}
          </div>
          <div className="flex flex-wrap gap-2 sm:gap-4 items-center mb-2">
            {steps?.map((step, index) => (
              <div key={index} className="flex items-center gap-2">
                <div className="px-3 py-1 border-2 border-primary rounded-full">
                  <h3 className="text-secondary text-xs sm:text-sm">{step}</h3>
                </div>
                {index !== steps.length - 1 && (
                  <ArrowForwardIcon className="text-gray-500 text-sm sm:text-base md:text-lg" />
                )}
              </div>
            ))}
          </div>
          <Button
            disabled={""}
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {}}
            sx={{ backgroundColor: "primary" }}
          >
            Start with this template
          </Button>
        </div>
        <div className="flex  mt-[5%] justify-between">
          <div className=" md:w-[65%]  min-h-[400px] flex flex-col gap-4 p-4">
            <h1 className="text-primary font-semibold text-xl md:text-3xl">
              How it works
            </h1>
            <p className="text-tertiary">{description}</p>
          </div>
          <div className="bg-primary gap-8 md:w-[25%] min-h-[400px] flex flex-col justify-between p-4 rounded-lg">
            <h1 className="text-primary font-semibold text-xl md:text-3xl">
              Used in this template
            </h1>
            {integrations?.map((integration, index) => (
              <div key={index} className="flex items-center gap-2">
                <div className="px-3 py-1 flex items-center gap-4">
                  <img src={IntegrationLogo} alt="" className="h-[36px]" />
                  <h3 className="text-primary text-md-regular sm:text-sm">
                    {integration}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-[5%]">
          <h1 className="text-primary font-semibold text-xl md:text-3xl">
            Other templates
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12  mt-[25%] md:mt-[10%]  lg:mt-[4%] ">
            {loading ? (
              <div className="flex justify-center items-center w-full ">
                <CircularProgress />
              </div>
            ) : (
              templates?.items?.map((item, index) => (
                <div key={index}>
                  <TemplateCard
                    logo={getLogo(item.short_title)}
                    short_title={item.short_title}
                    short_description={item.short_description}
                    steps={item.process || ""}
                    footerText="View template"
                    templateId={item.id}
                    title={item.title}
                    description={item.description}
                    integrations={item.integrations}
                  />
                </div>
              ))
            )}
          </div>
        </div>
        {!loading && templates?.items?.length !== 0 && (
          <div className="flex justify-center mt-[5%]">
            <Button
              disabled={""}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate("/s1/templates")}
              sx={{ backgroundColor: "primary" }}
            >
              View all
            </Button>
          </div>
        )}
      </div>
      <div className="md:mt-[4%] mt-[10%]">
        <div className="datafooter items-center justify-between py-[32px]  w-full">
          <div className="flex items-center ">
            <div>
              <NavbarLogo width={"142px"} height={"32px"} />
            </div>
          </div>
          <div className="text-md-regular  text-[#94969C]">
            © 2024 Scalaix. All rights reserved.
          </div>
        </div>
      </div>
    </main>
  );
};

export default AgentTemplateDetails;
