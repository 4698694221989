import snackbarService from "@Services.App/snackbar";
import { RocketLaunchOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { publishCollection } from "../../../services/app/api";
import { formatTimestampToDate } from "../../../services/generalFunctions";
import { handleLoading } from "../../../store/collectionsSlice";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import MuiCheckbox from "../../UI/MuiCheckbox";
import MuiModal from "../../UI/MuiModal";
import Textarea from "../../UI/Textarea";

const PublishCollection = (props) => {
  const { open, handleClose } = props;
  const { mode, colors } = useThemeContext();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [changeLog, setChangeLog] = useState("");
  const [errors, setErrors] = useState("");
  const [newVersion, setNewVersion] = useState(false);
  const navigate = useNavigate();
  const handlePublishCollection = async () => {
    if (!changeLog) {
      setErrors("Please enter changelog");
      return;
    }
    dispatch(handleLoading(true));
    try {
      const res = await publishCollection({
        collection_id: params.collectionId,
        data: {
          changelog: changeLog,
          new_version: newVersion,
        },
      });
      snackbarService.success({
        title: `Collection published successfully`,
      });
      window.location.reload();
      // navigate("/onboard/collections", { replace: true });
    } catch (error) {
      console.log(error);
      snackbarService.error({
        title: `${error.error && error.error.toString()}`,
      });
    } finally {
      setChangeLog("");
      setNewVersion(false);
      dispatch(handleLoading(false));
      handleClose();
    }
  };
  return (
    <MuiModal open={open} handleClose={handleClose}>
      <div className="relative">
        <div className="flex items-center gap-4 px-10 py-6">
          <div className="p-4 flex items-center justify-center rounded-full text-brandPrimary dark:text-textPrimary-light bg-brandSecondary-light dark:bg-brandPrimary">
            <RocketLaunchOutlined />
          </div>
          <div className="flex justify-between items-start w-full">
            <div>
              <Typography
                id="modal-modal-title"
                variant="p"
                className="font-geometric text-[26px] text-primary"
              >
                Deploy
              </Typography>
              <div className="flex">
                <p className="text-sm text-tertiary">
                  {location.state?.collection?.name} - Last edited:{" "}
                  {formatTimestampToDate(
                    location.state?.collection?.updated_at,
                    "MM/DD/YYYY"
                  )}
                  {" at "}
                  {formatTimestampToDate(
                    location.state?.collection?.updated_at,
                    "hh:mm A"
                  )}
                </p>
              </div>
            </div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon className="text-tertiary" />
            </IconButton>
          </div>
        </div>
        <div className="p-3 border-b-1 border-primary">
          <div className="mt-2">
            <p className="text-sm mb-2 text-tertiary">Changelog</p>
            <Textarea
              value={changeLog}
              onChange={(e) => setChangeLog(e.target.value)}
              id={`filledInput`}
              style={{
                background: colors.bgPrimary,
                outline: "none",
                padding: "10px 14px",
                color: colors.textPrimary,
                fontSize: "16px",
                width: "100%",
                resize: "none",
              }}
              minRows={4}
              maxRows={4}
              onFocus={() => setErrors("")}
              placeholder={"Enter description"}
              className={`border-[1px] border-transparent hover:border-white rounded-[4px] focus:border-primary `}
            />
            <p className="text-[10px] text-error min-h-3">{errors}</p>
          </div>
          <FormControlLabel
            label={
              <span className="text-tertiary text-sm">
                Deploy as new version
              </span>
            }
            sx={{ fontSize: "14px" }}
            control={<MuiCheckbox checked={newVersion} />}
            onChange={(e) => setNewVersion(e.target.checked)}
          />
        </div>
        <div className="flex justify-end m-4 mr-2 sm:mr-6 gap-4 ">
          <Button
            sx={
              mode === "light" && {
                color: "#344054",
                borderColor: "#D0D5DD",
              }
            }
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handlePublishCollection}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

export default PublishCollection;
