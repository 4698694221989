import { Stack } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getAssistantAnalytics,
  getAssistantErrors,
  getPromptAnalytics,
  getPromptErrors,
  updateAssistantErrors,
  updatePromptErrors,
} from "../../../services/app/api";
import snackbar from "../../../services/app/snackbar";
import { days, months, weeks } from "../../../services/constants";
import {
  formatCurrency,
  formatNumber,
} from "../../../services/generalFunctions";
import { handleLoading } from "../../../store/collectionsSlice";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import { ErrorOrWarningIcon, GoDownIcon } from "../../UI/IconPack";
import MuiSelect from "../../UI/MuiSelect";

const Hr = () => <div className="border-b-1 my-3  border-primary" />;
const Title = ({ children, className = "" }) => (
  <p
    className={`text-sm font-medium text-tertiary uppercase pb-5 ${className}`}
  >
    {children}
  </p>
);
const Label = ({ className = "", children }) => (
  <p className={`text-sm  text-primary  ${className}`}>{children}</p>
);
const Value = ({ className = "", children }) => (
  <p className={`text-2xl text-primary  ${className}`}>{children}</p>
);

const emptySummary = {
  cost: 0,
  total_count: 0,
  no_of_response: 0,
  tokens: 0,
  response_time: 0,
  total_failure: 0,
  avg_response_time: 0,
  success: 0,
};

const DashboardAnalytics = (props) => {
  const { assistanceId = null, promptId = null, isActive = true } = props;
  const { mode, colors } = useThemeContext();
  const [selectedTime, setSelectedTime] = useState("day");
  const [summary, setSummary] = useState(emptySummary);
  const [lineGraphData, setLineGraphData] = useState([]);
  const lineGraphXAxisData = lineGraphData?.map(({ x }) => x) || [];
  const lineGraphYAxisData = lineGraphData?.map(({ y }) => y) || [];
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pieChartPalette =
    mode === "dark"
      ? ["#9B8AFB", "#333741", "#7A5AF8"]
      : ["#6938EF", "#EAECF0", "#7A5AF8"];

  const xAxisLabel = {
    day: "Hours",
    week: "Days",
    month: "Weeks",
    year: "Months",
    all_time: "Years",
  };
  const menuList = [
    {
      value: "day",
      label: "Today",
    },
    {
      value: "week",
      label: "7 days",
    },
    {
      value: "month",
      label: "Last 30 days",
    },
    {
      value: "year",
      label: "Last year",
    },
    {
      value: "all_time",
      label: "All time",
    },
  ];

  const formatTimestamp = (timestamp) => {
    const time = new Date(timestamp);

    switch (selectedTime) {
      case "day":
        let hour = time.getHours();
        const period = hour >= 12 ? "PM" : "AM";
        hour = hour % 12 || 12;
        return `${hour}${period}`;

      case "week":
        const dayIndex = (time.getDay() + 7) % 7;
        return days[dayIndex];

      case "month":
        const firstDayOfMonth = new Date(
          time.getFullYear(),
          time.getMonth(),
          1
        );

        const firstSundayOffset = firstDayOfMonth.getDay();

        const dayOfMonth = time.getDate();

        const adjustedDayOfMonth = dayOfMonth + firstSundayOffset;

        const weekNumber = Math.ceil(adjustedDayOfMonth / 7);

        return weeks[weekNumber - 1];
      case "year":
        return months[time.getMonth()];

      case "all_time":
        return time.getFullYear();

      default:
        return timestamp;
    }
  };

  const generateAverageByTimestamp = (timestamp, data) => {
    const today = new Date();
    let filteredData = [];

    switch (timestamp) {
      case "day":
        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return itemDate.getDate() === today.getDate();
        });
        break;

      case "week":
        const currentDayOfWeek = today.getDay();
        const daysToMonday = (currentDayOfWeek + 6) % 7;
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - daysToMonday);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return itemDate >= startOfWeek && itemDate <= endOfWeek;
        });
        break;

      case "month":
        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return (
            itemDate.getMonth() === today.getMonth() &&
            itemDate.getFullYear() === today.getFullYear()
          );
        });
        break;

      case "year":
        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return itemDate.getFullYear() === today.getFullYear();
        });
        break;

      case "all_time":
        filteredData = data;
        break;

      default:
        return null;
    }

    if (filteredData.length === 0) {
      setLineGraphData([]);
      setSummary({});
      return;
    }

    const averageResult = {};
    const properties = Object.keys(filteredData[0].result);

    properties.forEach((property) => {
      averageResult[property] = filteredData.reduce(
        (acc, curr) => acc + (curr.result[property] || 0),
        0
      );
      if (property === "avg_response_time") {
        averageResult[property] /= filteredData.length;
      }
    });

    const sortedData = filteredData
      .map((item) => ({
        ...item,
        timestamp: new Date(item.timestamp),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    const chartData = sortedData.map((item) => ({
      x: formatTimestamp(item.timestamp),
      y: item.result?.tokens || 0,
    }));

    if (chartData.length === 1) {
      chartData.unshift({ x: "", y: 0 });
    } else if (chartData.length > 1) {
      const firstPoint = chartData[0];
      if (firstPoint.y !== 0) {
        chartData.unshift({ x: "", y: 0 });
      }
    }
    setLineGraphData(chartData);
    setSummary(averageResult);
  };

  const fetchAnalytics = () => {
    const params = {
      time: selectedTime,
    };

    let apiToCall;
    let payload = {};
    if (!!promptId) {
      apiToCall = getPromptAnalytics;
      payload = { prompt_id: promptId, params };
    } else if (!!assistanceId) {
      apiToCall = getAssistantAnalytics;
      payload = { assistance_id: assistanceId, params };
    }
    apiToCall(payload)
      .then((res) => {
        if (res?.items?.length > 0) {
          generateAverageByTimestamp(selectedTime, res?.items);
        } else {
          setLineGraphData([]);
          setSummary(emptySummary);
        }
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      });
    // .finally(() => dispatch(handleLoading(false)));
  };

  const fetchErrors = () => {
    //  dispatch(handleLoading(true));
    const params = {
      time: selectedTime,
    };
    let apiToCall;
    let payload = {};
    if (!!promptId) {
      apiToCall = getPromptErrors;
      payload = { prompt_id: promptId, params };
    } else if (!!assistanceId) {
      apiToCall = getAssistantErrors;
      payload = { assistance_id: assistanceId, params };
    }
    apiToCall(payload)
      .then((res) => {
        setErrors(res?.items);
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      });
    // .finally(() => dispatch(handleLoading(false)));
  };
  useEffect(() => {
    fetchAnalytics();
    fetchErrors();
  }, [selectedTime, promptId, assistanceId]);

  const handleGoDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleDismiss = (errorId) => {
    dispatch(handleLoading(true));
    const data = {
      error_dismiss: true,
    };
    const apiToCall = !!assistanceId
      ? updateAssistantErrors
      : updatePromptErrors;
    apiToCall({ error_id: errorId, data })
      .then((res) => {
        fetchErrors();
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  return (
    <Stack
      spacing={2}
      p={5}
      pt={0}
      pb={assistanceId ? 5 : 2}
      maxWidth={assistanceId ? 1350 : 1250}
      m={"auto"}
      overflow={"auto"}
    >
      <div className="bg-secondary border-1 rounded-lg border-secondary p-5">
        <div className="flex gap-4 justify-between">
          <div>
            {isActive && (
              <div className="flex items-center gap-1 py-1 text-sm border-1 text-green-700 dark:text-green-300  bg-green-200 dark:bg-green-950 border-green-500 dark:border-green-800 px-2 rounded-full">
                <div className="h-[6px] w-[6px] rounded-full bg-[#17B26A] "></div>{" "}
                Active
              </div>
            )}
          </div>
          <div className="w-40">
            <MuiSelect
              menuItems={menuList}
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            />
          </div>
        </div>
        <Title>{t("overview")}</Title>
        <div className="flex gap-24 lg:gap-32 overflow-auto">
          <div>
            <Label>Time invoked</Label>
            <Value>
              {(summary?.total_count && formatNumber(summary?.total_count)) ||
                "0"}
            </Value>
          </div>
          <div>
            <Label>Number of responses</Label>
            <Value>
              {(summary?.no_of_response &&
                formatNumber(summary?.no_of_response)) ||
                "0"}
            </Value>
          </div>
          <div>
            <Label>Tokens consumed</Label>
            <div className="flex items-end gap-1">
              <Value>{formatNumber(summary?.tokens || 0)}</Value>{" "}
              <span className="text-base pb-[2px]">
                {(summary?.cost && formatCurrency(summary?.cost?.toFixed(1))) ||
                  ""}
              </span>
            </div>
          </div>
          <div>
            <Label>Avg. response time</Label>
            <div className="flex items-end gap-1">
              <Value>{summary?.avg_response_time?.toFixed(1) || "0"}</Value>{" "}
              <span className="text-base pb-[2px]">Seconds</span>
            </div>
          </div>
          <div>
            <Label>Errors/Issues</Label>
            <div className="flex items-end gap-2">
              <Value>
                {(summary?.total_failure &&
                  formatNumber(summary?.total_failure)) ||
                  "0"}
              </Value>
              {!!summary?.total_failure && (
                <div
                  onClick={handleGoDown}
                  className="cursor-pointer max-h-[44px] max-w-[114px] flex items-center gap-2 border-1 border-yellow-800 bg-yellow-950 text-sm text-yellow-500 py-1 px-2 rounded-full "
                >
                  See details{" "}
                  <GoDownIcon color={"#F79009"} height={8} width={8} />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <Hr />

        <Title>{t("tokens consumed")}</Title>
        <div className="relative overflow-auto flex gap-4 justify-between">
          {lineGraphYAxisData?.length > 0 ? (
            <div className="ml-8">
              <LineChart
                width={assistanceId ? 780 : 720}
                height={300}
                sx={{
                  "& .MuiAreaElement-root": {
                    fill: "url('#myGradient')",
                  },
                  "& .MuiChartsAxis-tick": {
                    strokeWidth: "0",
                  },
                  //change left yAxis label styles
                  "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                    strokeWidth: "0",
                    fill: colors?.tertiary,
                  },

                  // change bottom label styles
                  "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                    strokeWidth: "0",
                    fill: colors?.tertiary,
                  },
                  // bottomAxis Line Styles
                  "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                    fill: colors?.tertiary,
                    strokeWidth: 0,
                  },
                  // leftAxis Line Styles
                  "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                    fill: colors?.tertiary,
                    strokeWidth: 0,
                  },
                }}
                series={[
                  {
                    data: lineGraphYAxisData,
                    type: "line",
                    label: "Token",
                    area: true,
                    stack: "total",
                    showMark: false,
                    color: mode === "dark" ? "#9B8AFB" : "#6938EF",
                  },
                ]}
                xAxis={[
                  {
                    scaleType: "point",
                    data: lineGraphXAxisData,
                  },
                ]}
                slotProps={{
                  legend: {
                    hidden: true,
                  },
                }}
              >
                <defs>
                  <linearGradient
                    id="myGradient"
                    gradientTransform="rotate(90)"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "rgba(155,138,251,1)" }}
                    />
                    <stop
                      offset="90%"
                      style={{
                        stopColor:
                          mode === "dark"
                            ? "rgba(0,0,0,0.1)"
                            : "rgba(255,255,255,0.2)",
                      }}
                    />
                    <stop
                      offset="100%"
                      style={{
                        stopColor:
                          mode === "dark"
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0)",
                      }}
                    />
                  </linearGradient>
                </defs>
              </LineChart>

              <div className="text-sm text-tertiary text-center">
                {xAxisLabel[selectedTime] || ""}
              </div>
            </div>
          ) : (
            <div className="text-center text-primary h-40 flex items-center w-80 justify-center">
              <div>No data available</div>
            </div>
          )}
          {lineGraphYAxisData?.length > 0 && (
            <div className="absolute top-1/2 -translate-y-1/2 -rotate-90 text-sm text-tertiary text-center">
              Tokens
            </div>
          )}
          <div>
            {summary?.no_of_response == 0 && summary?.total_failure == 0 ? (
              <div className="text-center text-primary h-40 flex items-center w-80 justify-center">
                <div>No data available</div>
              </div>
            ) : (
              <PieChart
                colors={pieChartPalette}
                sx={{
                  "& .MuiPieArc-root": {
                    strokeWidth: 0,
                  },
                }}
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: summary?.no_of_response,
                        label: "Success",
                      },
                      {
                        id: 1,
                        value: summary?.total_failure,
                        label: "Failed",
                      },
                    ],
                    innerRadius: 70,
                    outerRadius: 100,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 148,
                    cy: 150,
                  },
                ]}
                width={assistanceId ? 400 : 350}
                height={300}
                slotProps={{
                  legend: {
                    labelStyle: {
                      tableLayout: "fixed",
                    },
                    direction: "column",
                    position: {
                      horizontal: "right",
                      vertical: "top",
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="bg-secondary border-1 rounded-lg border-secondary p-5">
        <Title>{t("errors & issues")}</Title>
        <div className="flex flex-col gap-4 items-center justify-start">
          {errors?.length > 0 ? (
            errors?.map((item, index) => (
              <div
                key={index}
                className="bg-primary border-1 border-primary p-4 rounded-lg w-full"
              >
                <div>
                  <ErrorOrWarningIcon color={true ? "#F04438" : "#F79009"} />
                </div>
                <p className="text-sm text-secondary">{item?.error}</p>
                <div>
                  <div
                    onClick={() => handleDismiss(item?.id)}
                    className="text-sm font-semibold text-tertiary pl-0 p-2 cursor-pointer w-fit"
                  >
                    Dismiss
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-primary">No errors found</p>
          )}
        </div>
      </div>
    </Stack>
  );
};

export default DashboardAnalytics;
