import agentLogos from "@Images/agent-logos.png";
import gmailLogo from "@Images/gmail.png";
import googleSheetLogo from "@Images/google-sheet.png";
import pdfExtractorLogo from "@Images/pdf-extractor.png";
//import videoplayerLogo from "@Images/video-player.png";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getAgentTemplates } from "../../services/app/api";
import snackbar from "../../services/app/snackbar";
import { categories } from "../../services/constants";
import FilterField from "../UI/FilterField";
import { NavbarLogo } from "../UI/IconPack";
import MuiSelect from "../UI/MuiSelect";
import PaginationElement from "../UI/PaginationElement";
import TemplateCard from "../UI/TemplateCard";
import Navigation from "./Navigation";

const AgentTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Featured");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 4;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTemplates();
  }, [selectedCategory, page]);

  const fetchTemplates = () => {
    setLoading(true);
    getAgentTemplates({ tag: selectedCategory, limit, page })
      .then((response) => {
        console.log(
          `API Response for ${selectedCategory}, Page ${page}:`,
          response
        );
        setTemplates(response || []);
        setTotalPages(response.pages || 1);
      })
      .catch(
        (error) => snackbar.error(error.error) || "Failed to fetch templates"
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeInPagination = (event, value) => {
    setPage(value);
  };

  const getLogo = (title) => {
    if (title === "Email PDF attachment Extractor") return gmailLogo;
    if (title === "PDF to Google Sheet Extractor") return googleSheetLogo;
    return pdfExtractorLogo;
  };
  return (
    <main className="px-[5%] bg-black ">
      <div className="min-h-screen">
        <div className="">
          <Navigation />
        </div>
        <div className="px-[5%]  flex justify-center items-center flex-col gap-6 py-16 text-white ">
          <div className="display-lg-semibold text-center">Templates</div>
          <div className="text-lg-regular text-secondary text-center">
            Build fantastic apps on top of existing workflows.<br></br> Save
            time. Learn
          </div>
          <div className="flex justify-center items-center relative">
            <div className="relative">
              <img
                src={agentLogos}
                alt="Agent Logos"
                className="h-auto w-full"
              />
              <div
                className="absolute inset-0"
                style={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 95%)",
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-wrap   justify-between items-center mt-[5%] max-h-[44px]">
          <div className="flex md:flex-row  gap-2 justify-between">
            {categories.map((category, index) => (
              <h2
                key={index}
                className={`text-secondary px-4 py-2 rounded-lg whitespace-nowrap cursor-pointer hidden lg:block ${
                  selectedCategory === category ? "bg-gray-900" : ""
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </h2>
            ))}
            <div className="lg:hidden  max-w-[602px]">
              {" "}
              <MuiSelect
                menuItems={categories}
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                }}
                selectClassName={"bg-primary "}
              />
            </div>
          </div>

          <div className=" max-w-[602px] items-center">
            <FilterField
              placeholder="Search"
              value={""}
              onChange={(e) => {}}
              onReset={""}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-[25%] md:mt-[10%] lg:mt-[4%]">
          {loading ? (
            <div className="flex justify-center items-center w-full">
              <CircularProgress />
            </div>
          ) : templates?.items?.length > 0 ? (
            templates.items.map((item, index) => (
              <div key={index}>
                <TemplateCard
                  logo={getLogo(item.short_title)}
                  short_title={item.short_title}
                  short_description={item.short_description}
                  steps={item.process || ""}
                  footerText="View template"
                  templateId={item.id}
                  title={item.title}
                  description={item.description}
                  integrations={item.integrations}
                />
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center w-full text-center text-gray-400 text-lg col-span-full">
              No templates available
            </div>
          )}
        </div>

        <div className="mt-[5%]">
          {totalPages > 1 && (
            <PaginationElement
              handleChangeInPagination={handleChangeInPagination}
              total={totalPages}
              page={page}
            />
          )}
        </div>
      </div>
      <div className="mt-[4%]">
        <div className="datafooter items-center justify-between py-[32px]  w-full">
          <div className="flex items-center ">
            <div>
              <NavbarLogo width={"142px"} height={"32px"} />
            </div>
          </div>
          <div className="text-md-regular  text-[#94969C]">
            © 2024 Scalaix. All rights reserved.
          </div>
        </div>
      </div>
    </main>
  );
};

export default AgentTemplates;
