import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit"; // Import an edit icon
import React, { useEffect, useRef, useState } from "react";

function AppTabs(props) {
  const {
    list = [],
    handleAddNew,
    handleCloseTab,
    handleSetActiveTab,
    onBlur,
    isPreviewMode = false,
  } = props;

  const divRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const [allowEditName, setAllowEditName] = useState(null);
  const [promptName, setPromptName] = useState("");
  const [haveScroll, setHaveScroll] = useState(false);
  const [newTab, setNewTab] = useState(false);

  const scrollFull = () => {
    setTimeout(() => {
      const divElement = divRef.current;
      if (divElement) {
        divElement.scrollTo({
          left: divElement.scrollWidth - divElement.clientWidth,
          behavior: "smooth",
        });
      }
    }, 200);
  };

  const handleButtons = () => {
    const divElement = divRef.current;
    setShowLeftButton(divElement.scrollLeft > 10);
    setShowRightButton(
      divElement.scrollLeft <
        divElement.scrollWidth - divElement.clientWidth - 10
    );
  };

  useEffect(() => {
    const divElement = divRef.current;
    if (divElement) {
      handleButtons();
      setHaveScroll(divElement?.clientWidth < divElement?.scrollWidth);
    }
    setNewTab(false);
  }, [list]);

  const handleScrollLeft = () => {
    const divElement = divRef.current;
    if (divElement) {
      divElement.scrollTo({
        left: divElement.scrollLeft - 150,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    const divElement = divRef.current;
    if (divElement) {
      divElement.scrollTo({
        left: divElement.scrollLeft + 150,
        behavior: "smooth",
      });
    }
  };

  const handleNameChange = (e) => {
    setPromptName(e.target.value);
    onBlur(e);
  };

  return (
    <div className="flex sticky top-0 bg-secondary z-10 items-center border-y-1 border-primary ">
      {haveScroll && (
        <div
          onClick={handleScrollLeft}
          className="h-10 w-10 cursor-pointer flex justify-center items-center p-2 border-x-1 border-primary"
        >
          <ChevronLeftIcon
            fontSize="small"
            color={showLeftButton ? "secondary" : "disabled"}
          />
        </div>
      )}
      <div
        style={{
          width: "100%",
        }}
        ref={divRef}
        onScroll={handleButtons}
        className="app-tabs-bar flex overflow-x-auto "
      >
        {list?.length > 0 &&
          list.map((item) => (
            <div
              key={item?.id}
              title={item.name}
              className={`${
                !newTab &&
                item?.isActiveTab &&
                "border-b-2 border-b-brandPrimary-light dark:border-b-brandPrimary"
              } flex justify-between items-center ${
                isPreviewMode
                  ? "border-x-1 border-primary"
                  : "border-l-1 border-primary"
              }`}
              style={{ minWidth: "180px", width: "200px" }}
            >
              {item?.id === allowEditName && item?.isActiveTab ? (
                <div>
                  <input
                    value={promptName}
                    className="w-full bg-secondary text-sm-medium pl-4 pr-2 my-2 focus:outline-none"
                    onChange={handleNameChange}
                  />
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    setNewTab(false);
                    handleSetActiveTab(e, item);
                  }}
                  className="pl-4 pr-2 my-2 text-sm-medium cursor-pointer w-full whitespace-nowrap overflow-hidden text-ellipsis flex items-center"
                >
                  {item.name}
                  {item?.isActiveTab && (
                    <EditIcon
                      fontSize="small"
                      className="ml-[10%] cursor-pointer text-quaternary"
                      title="Edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAllowEditName(item?.id);
                        setPromptName("");
                      }}
                    />
                  )}
                </div>
              )}
              <div
                onClick={(e) => handleCloseTab(e, item)}
                className="pr-1 cursor-pointer text-quaternary"
                title="Close"
              >
                <CloseIcon fontSize="small" />
              </div>
            </div>
          ))}
        {!isPreviewMode && (
          <div
            onClick={(e) => {
              setNewTab(true);
              handleAddNew(e);
              scrollFull();
            }}
            className="sticky h-10 -right-[1px] cursor-pointer flex justify-center items-center p-2 border-x-1 border-primary bg-secondary"
          >
            <AddIcon fontSize="small" />
          </div>
        )}
      </div>
      {haveScroll && (
        <div
          onClick={handleScrollRight}
          className="h-10 w-10 cursor-pointer flex justify-center items-center p-2 border-x-1 border-primary"
        >
          <ChevronRightIcon
            fontSize="small"
            color={showRightButton ? "secondary" : "disabled"}
          />
        </div>
      )}
    </div>
  );
}

export default AppTabs;
