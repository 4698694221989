import { CircularProgress } from "@mui/material";
import snackbarService from "@Services.App/snackbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deletePrompt } from "../../../../services/app/api";
import { emptyPrompt } from "../../../../services/constants";
import { formatOutputKeysToEdit } from "../../../../services/generalFunctions";
import {
  addToEditPrompt,
  fetchPrompts,
  handleLoading,
  removeFromEditPrompt,
  setModelsVersionsList,
  setSearchPrompt,
} from "../../../../store/collectionsSlice";
import FilterField from "../../../UI/FilterField";
import { DuplicateIcon, TrashIcon } from "../../../UI/IconPack";
import MuiMenu from "../../../UI/MuiMenu";
import MuiModal from "../../../UI/MuiModal";
import SimplePagination from "../../../UI/SimplePagination";

const PromptsList = () => {
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { allPromptsData, searchPrompt, aiModelsList } = newPromptsData;
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const loading = useSelector((state) => state.collectionsSlice.handleLoading);
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const params = useParams();
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const PromptItem = (prompt) => {
    const filterAndSetModelVersions = (llmModel, type) => {
      const model = aiModelsList?.filter((model) => model?.id === llmModel)[0];
      const versions = model?.versions?.filter(
        (item) => item?.model_type == type
      );
      dispatch(setModelsVersionsList(versions));
    };

    const handelAddToEdit = () => {
      const llmModel = prompt?.llm_version_data?.model_id || "";
      const model_type = prompt?.llm_version_data?.model_type || "";
      const cacheSettings =
        prompt?.cache_setting || emptyPrompt().cache_setting;
      dispatch(
        addToEditPrompt({
          ...prompt,
          isSaved: false,
          isEdited: false,
          llmModel,
          llmVersion: prompt?.llm_version_data?.id || "",
          llmVersionName: prompt?.llm_version_data?.version_name || "",
          llmModelName: prompt?.llm_version_data?.model_name || "",
          model_type,
          outputFormat: prompt?.output_format,
          promptOutput: "",
          environment_id: prompt?.environment_data?.id,
          processType: prompt?.prompt_type || "create",
          variationCount: prompt?.variation_count || 1,
          imageSize: prompt?.size || "1024x1024",
          activeScreen: "Prompt Data",
          expandFormatter: true,
          output_keys: formatOutputKeysToEdit(prompt),
          errors: [],
          cache_setting: cacheSettings,
          compare_data: [],
          iteration_cnt: 1,
          compareResponse: {},
          iterationResponse: [],
          compareResultResponse: {},
        })
      );
      filterAndSetModelVersions(llmModel, model_type);
    };

    const handleDuplicate = () => {
      const llmModel = prompt?.llm_version_data?.model_id || "";
      const model_type = prompt?.llm_version_data?.model_type || "";
      const cacheSettings =
        prompt?.cache_setting || emptyPrompt().cache_setting;
      dispatch(
        addToEditPrompt({
          ...{
            ...prompt,
            name: `${prompt?.name}-Copy`,
            id: `newPromptId${Math.random()}`,
          },
          isSaved: false,
          isEdited: true,
          llmModel,
          llmVersion: prompt?.llm_version_data?.id || "",
          llmVersionName: prompt?.llm_version_data?.version_name || "",
          llmModelName: prompt?.llm_version_data?.model_name || "",
          model_type,
          outputFormat: prompt?.output_format,
          promptOutput: "",
          environment_id: prompt?.environment_data?.id,
          processType: prompt?.prompt_type || "create",
          variationCount: prompt?.variation_count || 1,
          imageSize: prompt?.size || "1024x1024",
          activeScreen: "Prompt Data",
          expandFormatter: true,
          output_keys: formatOutputKeysToEdit(prompt),
          errors: [],
          cache_setting: cacheSettings,
          compare_data: [],
          iteration_cnt: 1,
          compareResponse: {},
          iterationResponse: [],
          compareResultResponse: {},
        })
      );
      filterAndSetModelVersions(llmModel, model_type);
    };
    const handleRename = () => {
      handelAddToEdit();
    };
    const handleRemove = () => {
      setOpenDeletePrompt(prompt?.id);
    };

    const menuListItems = [
      // {
      //   icon: <EditIcon />,
      //   title: "Rename",
      //   onClick: handleRename,
      // },
      { icon: <DuplicateIcon />, title: "Duplicate", onClick: handleDuplicate },
      {
        icon: <TrashIcon color={"#F97066"} />,
        title: <span className="text-error">Remove</span>,
        onClick: handleRemove,
      },
    ];
    return (
      <div
        key={prompt?.id}
        title={prompt?.name}
        onClick={handelAddToEdit}
        className={`group/item border-y-[0.5px] border-primary pl-4 cursor-pointer ${
          promptData?.id === prompt?.id && "bg-quaternary"
        } `}
      >
        <div className="flex items-center my-2 justify-between h-8 w-64">
          <p className="text-sm text-primary  whitespace-nowrap overflow-hidden text-ellipsis">
            {prompt?.name}
          </p>
          <div className="invisible group-hover/item:visible">
            <MuiMenu menuListItems={menuListItems} />
          </div>
        </div>
      </div>
    );
  };

  const handleNextPagePrompts = () => {
    var payload = {
      collection_id: params?.collectionId,
      params: {
        page: allPromptsData?.page + 1,
        limit: 10,
        searchKey: searchPrompt,
      },
    };
    dispatch(fetchPrompts(payload));
  };
  const handlePrevPagePrompts = () => {
    var payload = {
      collection_id: params?.collectionId,
      params: {
        page: allPromptsData?.page - 1,
        limit: 10,
        searchKey: searchPrompt,
      },
    };
    dispatch(fetchPrompts(payload));
  };

  const handleDeletePrompt = () => {
    dispatch(handleLoading(true));
    deletePrompt(openDeletePrompt)
      .then((res) => {
        var payload = {
          collection_id: params?.collectionId,
          params: {
            page: allPromptsData?.page || 1,
            limit: 10,
            searchKey: searchPrompt,
          },
        };
        dispatch(removeFromEditPrompt(openDeletePrompt));
        dispatch(fetchPrompts(payload));
      })
      .catch((error) => {
        console.log(error);
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        setOpenDeletePrompt(false);
      });
  };

  const handleResetSearch = () => {
    dispatch(setSearchPrompt(""));
    var payload = {
      collection_id: params?.collectionId,
      params: {
        page: 1,
        limit: 10,
      },
    };
    dispatch(fetchPrompts(payload));
  };
  return (
    <div className="relative top-0 border-1 h-full border-primary">
      <div className="p-2 w-full flex gap-2 sticky top-0 z-50 bg-secondary">
        <FilterField
          value={searchPrompt}
          onChange={(e) => dispatch(setSearchPrompt(e.target.value))}
          onReset={handleResetSearch}
        />
      </div>
      <MuiModal
        open={!!openDeletePrompt}
        handleClose={() => setOpenDeletePrompt(false)}
        title={"Delete Prompt"}
        message={
          "Are you sure you want to delete this prompt? This action cannot be undone."
        }
        handleConfirm={handleDeletePrompt}
        showDeleteIcon={true}
      />
      <div style={{ maxWidth: "100%" }}>
        {!loading && allPromptsData?.items?.length > 0
          ? allPromptsData.items.map((prompt, index) => (
              <PromptItem key={prompt?.id} {...{ ...prompt, index }} />
            ))
          : !loading && (
              <div className="flex items-center my-2 justify-center h-8 w-64">
                {" "}
                <CircularProgress size={20} />
              </div>
            )}
      </div>
      {allPromptsData?.pages > 1 && !loading && (
        <div className="absolute bottom-0 z-50 flex justify-around items-center w-full border-t-1 bg-secondary border-primary">
          <SimplePagination
            onPrevious={handlePrevPagePrompts}
            onNext={handleNextPagePrompts}
            pagination={allPromptsData}
          />
        </div>
      )}
    </div>
  );
};

export default PromptsList;
